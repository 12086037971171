<template>
  <div
    class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-6 w-full"
    style="border: solid 2px #ededed"
  >
    <div class="flex flex-row gap-x-1">
      <img src="/svg/consultation/confidential-note.svg" />
      <h1 class="text-xl font-EffraR text-black">Imagerie médicale</h1>
    </div>

    <hr class="border border-solid border-gray-200" />

    <div v-if="!loader" class="font-EffraR text-lg flex flex-col gap-y-5">
      <div class="grid gap-x-5 gap-y-10 grid-cols-2">
        <div class="flex flex-col gap-y-3">
          <h1 class="font-EffraM text-lg text-labelGray">Radiographie :</h1>
          <div
            v-if="RadiographieItems.length <= 0"
            class="flex flex-row gap-x-1 text-base font-EffraM"
            style="color: #8a8a8a"
          >
            <img src="/svg/consultation/info/x.svg" /> Pas de données
          </div>
          <div v-else v-for="(item, index) in RadiographieItems" :key="index">
            {{
              item.refId
                ? item.refId.name
                  ? item.refId.name
                  : item.refId.description
                  ? item.refId.description
                  : "--"
                : "--"
            }}
          </div>
        </div>

        <div class="flex flex-col gap-y-3">
          <h1 class="font-EffraM text-lg text-labelGray">Interventionnel :</h1>
          <div
            v-if="InterItems.length <= 0"
            class="flex flex-row gap-x-1 text-base font-EffraM"
            style="color: #8a8a8a"
          >
            <img src="/svg/consultation/info/x.svg" /> Pas de données
          </div>
          <div v-else v-for="(item, index) in InterItems" :key="index">
            {{
              item.refId
                ? item.refId.name
                  ? item.refId.name
                  : item.refId.description
                  ? item.refId.description
                  : "--"
                : "--"
            }}
          </div>
        </div>

        <div class="flex flex-col gap-y-3">
          <h1 class="font-EffraM text-lg text-labelGray">RX :</h1>
          <div
            v-if="RXItems.length <= 0"
            class="flex flex-row gap-x-1 text-base font-EffraM"
            style="color: #8a8a8a"
          >
            <img src="/svg/consultation/info/x.svg" /> Pas de données
          </div>
          <div v-else v-for="(item, index) in RXItems" :key="index">
            {{
              item.refId
                ? item.refId.name
                  ? item.refId.name
                  : item.refId.description
                  ? item.refId.description
                  : "--"
                : "--"
            }}
          </div>
        </div>

        <div class="flex flex-col gap-y-3">
          <h1 class="font-EffraM text-lg text-labelGray">Scanner :</h1>
          <div
            v-if="ScannerItems.length <= 0"
            class="flex flex-row gap-x-1 text-base font-EffraM"
            style="color: #8a8a8a"
          >
            <img src="/svg/consultation/info/x.svg" /> Pas de données
          </div>
          <div v-else v-for="(item, index) in ScannerItems" :key="index">
            {{
              item.refId
                ? item.refId.name
                  ? item.refId.name
                  : item.refId.description
                  ? item.refId.description
                  : "--"
                : "--"
            }}
          </div>
        </div>

        <div class="flex flex-col gap-y-3">
          <h1 class="font-EffraM text-lg text-labelGray">Echographie :</h1>
          <div
            v-if="EchographieItems.length <= 0"
            class="flex flex-row gap-x-1 text-base font-EffraM"
            style="color: #8a8a8a"
          >
            <img src="/svg/consultation/info/x.svg" /> Pas de données
          </div>
          <div v-else v-for="(item, index) in EchographieItems" :key="index">
            {{
              item.refId
                ? item.refId.name
                  ? item.refId.name
                  : item.refId.description
                  ? item.refId.description
                  : "--"
                : "--"
            }}
          </div>
        </div>

        <div class="flex flex-col gap-y-3">
          <h1 class="font-EffraM text-lg text-labelGray">Sénologie :</h1>
          <div
            v-if="senologieItems.length <= 0"
            class="flex flex-row gap-x-1 text-base font-EffraM"
            style="color: #8a8a8a"
          >
            <img src="/svg/consultation/info/x.svg" /> Pas de données
          </div>
          <div v-else v-for="(item, index) in senologieItems" :key="index">
            {{
              item.refId
                ? item.refId.name
                  ? item.refId.name
                  : item.refId.description
                  ? item.refId.description
                  : "--"
                : "--"
            }}
          </div>
        </div>
      </div>
      <!-- <hr class="border-0 border-t border-solid border-grayxx my-2" />
      <div class="flex flex-col gap-y-1">
        <span class="text-base text-gray-600">Note :</span>
        <div v-html="data.note || '--'"></div>
      </div> -->
    </div>

    <div class="bg-grayxx w-full h-6 rounded animate-pulse" v-if="loader"></div>
  </div>
</template>

<script>
  export default {
    props: {
      consultationID: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        loader: true,
        data: { note: null, items: [] },
      };
    },
    created() {
      this.getListImageries();
    },
    computed: {
      // Sénologie, Radiographie, EOS, Echographie
      senologieItems() {
        return this.data.items.filter((item) => {
          return item.refId.category.toLowerCase() === "seno";
        });
      },
      RadiographieItems() {
        return this.data.items.filter((item) => {
          return item.refId.category.toLowerCase() === "irm";
        });
      },
      RXItems() {
        return this.data.items.filter((item) => {
          return item.refId.category.toLowerCase() === "rx";
        });
      },
      EchographieItems() {
        return this.data.items.filter((item) => {
          return item.refId.category.toLowerCase() === "echo";
        });
      },
      ScannerItems() {
        return this.data.items.filter((item) => {
          return item.refId.category.toLowerCase() === "scanner";
        });
      },
      InterItems() {
        return this.data.items.filter((item) => {
          return item.refId.category.toLowerCase() === "interventionnel";
        });
      },
    },
    methods: {
      async getListImageries() {
        this.$store.dispatch("consultation/GET_PRESCRIPTIONS", {
          id: this.consultationID,
          category: "IMAGERY",
          onData: async ({ ok, data }) => {
            if (ok) {
              if (data._id) {
                this.data.note = data.note;
                await this.$store.dispatch(
                  "consultation/GET_PRESCRIPTION_ITEMS",
                  {
                    id: data._id,
                    onData: ({ ok: okItems, data: dataItems }) => {
                      if (okItems) {
                        this.data.items = dataItems;
                      }
                    },
                  }
                );
                this.loader = false;
              }
            }
            if (!ok) {
              console.log("Error Loading imageries ...");
            }
          },
        });
      },
    },
  };
</script>

<style></style>
