<template>
  <div
    class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-6 w-full"
    style="border: solid 2px #ededed"
  >
    <!-- ---------------------------------------- Header -------------------------------------------------------------->
    <div class="flex flex-row gap-x-1">
      <img src="/svg/consultation/ultrasound.svg" />
      <h1 class="text-xl font-EffraR text-black">Soins paramédicaux</h1>
    </div>
    <hr class="border border-solid border-gray-200" />
    <!-- ---------------------------------------- Content ------------------------------------------------------------->
    <div v-if="!loader" class="font-EffraR text-lg flex flex-col gap-y-2">
      <template v-if="data.length > 0">
        <ul class="flex flex-col gap-y-2">
          <li
            v-for="(item, index) in data"
            :key="index"
            class="font-EffraR text-gray-700"
          >
            {{ index + 1 }} -
            {{ item.typeSoin ? item.typeSoin.name : "--" }}
          </li>
        </ul>
      </template>
      <template v-else>
        <div
          class="flex flex-row gap-x-1 text-base font-EffraM"
          style="color: #8a8a8a"
        >
          <img src="/svg/consultation/info/x.svg" /> Pas de données
        </div>
      </template>

      <!-- <hr class="border-0 border-t border-solid border-grayxx my-2" />
      <div class="flex flex-col gap-y-1">
        <span class="text-base text-gray-600">Note :</span>
        <div v-html="data.note || '--'"></div>
      </div> -->
    </div>

    <div class="bg-grayxx w-full h-6 rounded animate-pulse" v-if="loader"></div>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  export default {
    props: {
      consultationID: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        loader: true,
        data: [],
      };
    },
    mounted() {
      this.getListActs();
    },
    methods: {
      ...mapActions({
        getConsultationCertificates:
          "consultation/GET_SOINS_PARA_MEDICAL_CONSULTATION",
      }),
      async getListActs() {
        await this.getConsultationCertificates({
          consultationId: this.$route.params.id,
          onData: ({ ok, data }) => {
            if (ok) {
              this.data = data;
            }
            this.loader = false;
          },
        });
        // this.$store.dispatch("consultation/GET_PRESCRIPTIONS", {
        //   id: this.consultationID,
        //   category: "ACTS",
        //   onData: async ({ ok, data }) => {
        //     if (ok) {
        //       if (data._id) {
        //         this.data.note = data.note;
        //         await this.$store.dispatch(
        //           "consultation/GET_PRESCRIPTION_ITEMS",
        //           {
        //             id: data._id,
        //             onData: ({ ok: okItems, data: dataItems }) => {
        //               if (okItems) {
        //                 this.data.items = dataItems;
        //               }
        //             },
        //           }
        //         );
        //         this.loader = false;
        //       }
        //     }
        //     if (!ok) {
        //       console.log("Error Load Actes ...");
        //     }
        //   },
        // });
      },
    },
  };
</script>

<style></style>
