<template>
  <div
    class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-6 w-full"
    style="border: solid 2px #ededed"
  >
    <div class="flex flex-row gap-x-1">
      <img src="/svg/consultation/microscope-line.svg" />
      <h1 class="text-xl font-EffraR text-black">Analyses et bilans</h1>
    </div>

    <hr class="border border-solid border-gray-200" />

    <div v-if="!loader">
      <div class="grid gap-x-5 grid-cols-2">
        <div class="flex flex-col gap-y-2">
          <h1 class="font-EffraM text-lg text-labelGray">Biologie</h1>
          <div
            v-if="biologiqueItems.length <= 0"
            class="flex items-center justify-start gap-x-2 font-EffraM text-lg"
            style="color: #a3a3a3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="#A3A3A3"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 5L5 15M5 5l10 10"
              />
            </svg>
            Pas de données
          </div>
          <div
            v-for="(it, index) in biologiqueItems"
            :key="index"
            class="font-EffraR text-base text-black"
          >
            {{ it.name }}
          </div>
        </div>
        <div class="flex flex-col gap-y-2">
          <h1 class="font-EffraM text-lg text-labelGray">Anatomo-pathologie</h1>
          <div
            v-if="anatomyItems.length <= 0"
            class="flex items-center justify-start gap-x-2 font-EffraM text-lg"
            style="color: #a3a3a3"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="#A3A3A3"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 5L5 15M5 5l10 10"
              />
            </svg>
            Pas de données
          </div>
          <div
            v-for="(it, index) in anatomyItems"
            :key="index"
            class="font-EffraR text-base text-black"
          >
            {{ it.name }}
          </div>
        </div>
      </div>
      <!-- <hr class="border-0 border-t border-solid border-grayxx my-2" />
      <div class="flex flex-col gap-y-1">
        <span class="text-base text-gray-600">Note :</span>
        <div v-html="data.note || '--'"></div>
      </div> -->
    </div>

    <div class="bg-grayxx w-full h-6 rounded animate-pulse" v-if="loader"></div>
  </div>
</template>

<script>
  export default {
    props: {
      consultationID: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        loader: true,
        data: { note: null, items: [] },
      };
    },
    created() {
      this.getListAnalyses();
    },
    computed: {
      biologiqueItems() {
        return this.data.items.filter((filter) => {
          return filter.typeAnalyse === "biologic";
        });
      },
      anatomyItems() {
        return this.data.items.filter((filter) => {
          return filter.typeAnalyse === "anatomy";
        });
      },
    },
    methods: {
      async getListAnalyses() {
        this.$store.dispatch("consultation/GET_PRESCRIPTIONS", {
          id: this.consultationID,
          category: "BIOLOGICAL",
          onData: async ({ ok, data }) => {
            if (ok) {
              if (data._id) {
                this.data.note = data.note;
                await this.$store.dispatch(
                  "consultation/GET_PRESCRIPTION_ITEMS",
                  {
                    id: data._id,
                    onData: ({ ok: okItems, data: dataItems }) => {
                      if (okItems) {
                        this.data.items = dataItems;
                        this.loader = false;
                      }
                    },
                  }
                );
              }
            }
            if (!ok) {
              console.log("Error Loading ...");
            }
          },
        });
      },
    },
  };
</script>

<style></style>
