<template>
<div>
  <div v-if="!loader" class="flex flex-col gap-y-3">
    <dok-symptomes :consultationID="consultation._id"></dok-symptomes>
    <dok-exploration :consultationID="consultation._id"></dok-exploration>
    <dok-diagnostic :consultationID="consultation._id"></dok-diagnostic>
    <dok-note-confidentielle
      :consultationID="consultation._id"
    ></dok-note-confidentielle>
    <dok-medicaments :consultationID="consultation._id"></dok-medicaments>
    <dok-analyses-bilans
      :consultationID="consultation._id"
    ></dok-analyses-bilans>
    <dok-imageries :consultationID="consultation._id"></dok-imageries>
    <dok-actes :consultationID="consultation._id"></dok-actes>
  </div>
  <div v-if="loader" class="flex items-center justify-center py-5">
    <loader></loader>
  </div>
</div>
</template>

<script>
import { mapActions } from "vuex";
import loader from "@/views/global-components/loader";
import dokSymptomes from "@/views/dashboard/consultationsN/compte_rendu/layouts/symptomes.vue";
import dokExploration from "@/views/dashboard/consultationsN/compte_rendu/layouts/exploration.vue";
import dokDiagnostic from "@/views/dashboard/consultationsN/compte_rendu/layouts/diagnostic.vue";
import dokNoteConfidentielle from "@/views/dashboard/consultationsN/compte_rendu/layouts/note_confidentielle.vue";
import dokMedicaments from "@/views/dashboard/consultationsN/compte_rendu/layouts/medicaments.vue";
import dokAnalysesBilans from "@/views/dashboard/consultationsN/compte_rendu/layouts/analyses_bilans.vue";
import dokActes from "@/views/dashboard/consultationsN/compte_rendu/layouts/actes.vue";
import dokImageries from "@/views/dashboard/consultationsN/compte_rendu/layouts/imageries.vue";

export default {
  props: {
    consultationID: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      consultation: {},
      loader: true
    };
  },
  created() {
    this.getConsultaion({
      id: this.consultationID,
      onData: ({ data, ok }) => {
        if (ok) {
          this.consultation = data;
          this.$nextTick(() => {
            setTimeout(() => {
              this.loader = false;
            }, 1500);
          });
        }
      }
    });
  },
  methods: {
    ...mapActions({
      getConsultaion: "consultation/GET_BY_ID"
    })
  },
  components: {
    dokSymptomes,
    dokExploration,
    dokDiagnostic,
    dokNoteConfidentielle,
    dokMedicaments,
    dokAnalysesBilans,
    dokActes,
    dokImageries,
    loader
  }
};
</script>