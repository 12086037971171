<template>
  <div
    class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-6 w-full"
    style="border: solid 2px #ededed;"
  >
    <div class="flex flex-row gap-x-1">
      <img src="/svg/consultation/medicine-bottle-line.svg" />
      <h1 class="text-xl font-EffraR text-black">
        Médicaments
      </h1>
    </div>

    <hr class="border border-solid border-gray-200" />

    <div class="flex flex-col gap-y-3" v-if="!loader">
      <table
        class="border-collapse w-full"
        v-if="data.items && data.items.length"
      >
        <thead>
          <tr>
            <th
              class="py-3 font-EffraR font-normal text-base text-gray-600 border-0 border-b border-solid border-gray-300 hidden lg:table-cell"
            >
              Nom du Médicament
            </th>
            <th
              class="py-3 font-EffraR font-normal text-base text-gray-600 border-0 border-b border-solid border-gray-300 hidden lg:table-cell"
            >
              Durée
            </th>
            <th
              class="py-3 font-EffraR font-normal text-base text-gray-600 border-0 border-b border-solid border-gray-300 hidden lg:table-cell"
            >
              Dosage/prise
            </th>
            <th
              class="py-3 font-EffraR font-normal text-base text-gray-600 border-0 border-b border-solid border-gray-300 hidden lg:table-cell"
            >
              Nombre de prise/jour
            </th>
            <th
              class="py-3 font-EffraR font-normal text-base text-gray-600 border-0 border-b border-solid border-gray-300 hidden lg:table-cell"
            >
              Prise/Repas
            </th>
          </tr>
        </thead>
        <tbody class="w-full">
          <tr
            v-for="(item, i) in data.items"
            :key="i"
            class="bg-white flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
          >
            <td
              class="w-full xl:w-auto py-5 xl:py-3 px-2 xl:px-2 text-gray-800 border-0 border-b border-solid border-gray-200 block lg:table-cell relative lg:static"
            >
              <div
                class="lg:hidden px-0 py-2 text-xs text-gray-600 font-EffraR font-normal uppercase"
              >
                MÉDICAMENT
              </div>
              <span
                class="text-sm text-blackdok whitespace-normal font-EffraR"
                >{{ item.name }}</span
              >
            </td>
            <td
              class="w-full xl:w-auto py-5 xl:py-3 px-2 xl:px-2 text-gray-800 border-0 border-b border-solid border-gray-200 block lg:table-cell relative lg:static"
            >
              <div
                class="lg:hidden px-0 py-2 text-xs text-gray-600 font-EffraR font-normal uppercase"
              >
                DURÉE
              </div>
              <span
                class="text-sm text-blackdok whitespace-no-wrap font-EffraR"
                >{{ `${item.treatmentDuration} jour` }}</span
              >
            </td>
            <td
              class="w-full xl:w-auto py-5 xl:py-3 px-2 xl:px-2 text-gray-800 border-0 border-b border-solid border-gray-200 block lg:table-cell relative lg:static"
            >
              <div
                class="lg:hidden px-0 py-2 text-xs text-gray-600 font-EffraR font-normal uppercase"
              >
                Dosage/prise
              </div>
              <span
                class="text-sm text-blackdok whitespace-no-wrap font-EffraR"
              >
                {{
                  `${item.dailyDosage ? $toFraction(item.dailyDosage) : ""} ${
                    item.dailyDosage &&
                    item.doseUnit &&
                    $medicamentDosageUnits[item.doseUnit]
                      ? $medicamentDosageUnits[item.doseUnit].unity
                      : ""
                  }`
                }}
              </span>
            </td>
            <td
              class="w-full xl:w-auto py-5 xl:py-3 px-2 xl:px-2 text-gray-800 border-0 border-b border-solid border-gray-200 block lg:table-cell relative lg:static"
            >
              <div
                class="lg:hidden px-0 py-2 text-xs text-gray-600 font-EffraR font-normal uppercase"
              >
                Nombre de prise/jour
              </div>
              <span
                class="text-sm text-blackdok whitespace-no-wrap font-EffraR"
              >
                {{ `${item.numberOfTake ? item.numberOfTake + "x/jour" : ""}` }}
              </span>
            </td>

            <td
              class="w-full xl:w-auto py-5 xl:py-3 px-2 xl:px-2 text-gray-800 border-0 border-b border-solid border-gray-200 block lg:table-cell relative lg:static"
            >
              <div
                class="lg:hidden px-0 py-2 text-xs text-gray-600 font-EffraR font-normal uppercase"
              >
                PRISE/REPAS
              </div>
              <span class="text-sm text-blackdok font-EffraR">
                {{ $timesPeriod[item.timePeriod] }}
                {{ item.timePeriod && item.mealPeriod ? "/" : "" }}
                {{ $mealFrancais(item.mealPeriod) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-html="data.note || '--'"></div>
    </div>

    <div class="bg-grayxx w-full h-6 rounded animate-pulse" v-if="loader"></div>
  </div>
</template>

<script>
export default {
  props: {
    consultationID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loader: true,
      data: {}
    };
  },
  created() {
    this.getListMedicament();
  },
  methods: {
    async getListMedicament() {
      await this.$store.dispatch("consultation/GET_PRESCRIPTIONS", {
        id: this.consultationID,
        category: "DRUG",
        onData: async ({ ok, data }) => {
          if (ok) {
            this.data.note = data.note;
            await this.$store.dispatch("consultation/GET_PRESCRIPTION_ITEMS", {
              id: data._id,
              onData: ({ ok: okItems, data: dataItems }) => {
                if (okItems) {
                  this.data.items = dataItems;
                  this.loader = false;
                }
              }
            });
          }
        }
      });
    }
  }
};
</script>

<style></style>
