<template>
  <div
    class="bg-white px-8 rounded-lg py-5 flex flex-col gap-y-6 w-full"
    style="border: solid 2px #ededed;"
  >
    <div class="flex flex-row gap-x-1">
      <img src="/svg/consultation/hoarseness.svg" />
      <h1 class="text-xl font-EffraR text-black">
        Symptômes
      </h1>
    </div>

    <hr class="border border-solid border-gray-200" />

    <div v-if="!loader" v-html="data.note || '--'">
    </div>

    <div class="bg-grayxx w-full h-6 rounded animate-pulse" v-if="loader"></div>
  </div>
</template>

<script>
export default {
  props: {
    consultationID: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loader: true,
      data: {}
    };
  },
  created() {
    this.getSymptomes();
  },
  methods: {
    async getSymptomes() {
      await this.$store.dispatch("consultation/GET_SYMPTOMES", {
        id: this.consultationID,
        onData: ({ ok, data }) => {
          if (ok) {
            this.data = data;
            this.loader = false;
          }
        }
      });
    }
  }
};
</script>

<style></style>
